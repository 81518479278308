import React, {Component, Fragment} from 'react';
import OrgProducts from './checkout/OrgProducts';
import AddAccount from './manageSecurity/AddAccount';
//import Checkout from './Checkout';

class Security extends Component{

  render(){
    const {subpage, profile} = this.props;
    console.log(this.props.page);
    return(
      <Fragment>
      {subpage === 'org_prod'?
        <OrgProducts profile={profile}/>
      :
        null
      }

      {subpage === 'add-security'?
        <AddAccount profile={profile}/>
      :
        null
      }

      </Fragment>
    );
  }
}

export default Security;
