import React, {Component, Fragment} from 'react';

import Spinner from '../../common/Spinner'

class Organisation extends Component{

  render(){
    return(
      <Fragment>
      <Spinner />
      </Fragment>
    );
  }
}
export default Organisation;
