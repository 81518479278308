import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AddAccountForm from './AddAccountForm';
import Typography from '@material-ui/core/Typography';

const styles = theme =>({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2)
  },
  formControl:{
    margin: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon:{
    marginRight: 5,
  }
})

class AddAccount extends Component{

  render(){
    return(
      <Fragment>
        <Paper className={this.props.classes.paper}>
          <Typography color="primary">
           Add User with Security Priviledges
          </Typography>
          <AddAccountForm  classes={this.props.classes} profile={this.props.profile}/>
        </Paper>

      </Fragment>
    );
  }


}

export default withStyles(styles)(AddAccount);
