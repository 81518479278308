import React, {Component, Fragment} from 'react';
import Spinner from '../../common/Spinner';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import {organisationService} from '../../../_services/organisationService';


const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
})

class List extends Component{
  constructor(props){
    super(props)
    this.state = {
      loading:true,
      data:'',
    }
  }

  componentDidMount(){
      organisationService.organisations()
        .then(val=>{
          this.setState(state=>({
            data: val
          }))
        })
        .catch(error =>{
          alert(error)
        })

        const interval = setInterval( ()=>{
          if(this.state.data !== ''){
            this.setState({loading: false})
            clearInterval(interval);
          }
        },500)
  }
  render(){
    const data = this.state.data
    return(
      <Fragment>
      <Typography variant='subtitle1' color='primary'>
        Added Organisations
      </Typography>
      {this.state.loading?
        <Spinner />
        :
        <Paper className={this.props.classes.paper}  aria-label="sticky table">
          <Table className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Organisation Name</TableCell>
                <TableCell>Organisation Id</TableCell>
                <TableCell>Added at</TableCell>
                <TableCell>Active ?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map( val => (
                <TableRow key={val.id}>
                  <TableCell align="left">{val.org_name}</TableCell>
                  <TableCell align="left">{val.org_id}</TableCell>
                  <TableCell align="left">{new Date(val.created_at).toLocaleString()}</TableCell>
                  <TableCell align="left">{val.active? "true" : "false"}</TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </Paper>
      }

      </Fragment>
    );
  }
}
export default withStyles(styles)(List);
