import axios from  'axios';
import {authenticationService} from './authenticationService';


const issue = async (values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  const products = [];
  values.products.map( product =>{
    products.push(product.value)
    return ''
  })
  await axios.post(`${api_url}/api/v1/issue`, {
    first_name: values.first_name,
    last_name: values.last_name,
    product: products,
    title: values.title,
    person_id: values.person_id,
    issue_comment: values.comment
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const issuedList = async () =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/issue`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}


export const issueService = {
  issue,
  issuedList
}
