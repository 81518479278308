import axios from  'axios';
import {authenticationService} from './authenticationService';

const organisations = async ()=>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/organisations`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    if(resp.statusText === 'OK'){
      data = resp.data
      return (data)
    }
  })
  .catch( error=>{
    return error
  })
  return data
}

const addOrganisation = async (org_name, org_id)=>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/organisations`, {org_name: org_name, org_id: org_id}, {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
    .then(resp =>{
      data = resp.data
      return data
    })
    return data
}



export const organisationService = {
  organisations,
  addOrganisation
}
