import React, {Fragment, Component} from 'react';
import ProductList from './ProductList';
import IssueProduct from './IssueProduct';
import Status from './Status';

class Issue extends Component{

  render(){
    const {subpage} = this.props
    console.log(subpage)
    return(
      <Fragment>
        {subpage === 'add-product' ?
          <ProductList />
        :
        null
        }
        {subpage === 'issue-product' ?
          <IssueProduct />
        :
          null
        }
        {subpage === 'status' ?
          <Status />
        :
          null
        }
      </Fragment>
    );
  }
}

export default Issue;
