import axios from  'axios';
import {authenticationService} from './authenticationService';

const getProducts = async ()=>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/products`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const getPrototypes = async ()=>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/prototypes`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const addProduct = async (values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/products`, {
    type: values.product.value,
    serial_number: values.serial_number,
    is_returnable: values.returnable
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const addPrototype = async (values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/prototypes`, {
    product_type: values.type,
    product_brand: values.brand,
    model: values.model,
    color: values.color
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}


export const productService = {
  getPrototypes,
  getProducts,
  addProduct,
  addPrototype
}
