import React, {Component, Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

class Footer extends Component{

  render(){
    return(
    <Fragment>
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Copyright ©'}
        <Link color='inherit' href='https://adtel.co.ke' target='_blank' rel='noopener'>
          Adtel
        </Link>
        {' '}
        { new Date().getFullYear() }
        {'.'}

      </Typography>
    </Fragment>
    )
  }

}

export default Footer
