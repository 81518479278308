import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import DoneIcon from '@material-ui/icons/Done';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Spinner from '../../common/Spinner';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import {reportService} from '../../../_services/reportService';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';



const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
  },
  list:{
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }

})

const PaperComponent=(props)=>{
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


class Transactions extends Component{
  constructor(props){
    super(props);
    this.handleView = this.handleView.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state={
      loading:true,
      data:'',
      values:'',
      more:false,
      currentData:""
    }
  }

  handleView(id){
    const val = this.state.data.filter(record => record.id===id);
    this.setState({
      more:true,
      currentData: val
    })
  }

  handleClose(){
    this.setState({
      more:false,
      currentData:""
    })
  }

  getData(){
    reportService.events()
      .then(val=>{
        this.setState(state=>({
          data: val
        }))

      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.data !== ''){
          let values=[];
          this.state.data.map( val => {
            const entry = [`${val.first_name} ${val.last_name}`, val.person_id, val.product.length, val.issued_by, val.approve, val.check_out, val.receive_gadget, new Date(val.timestamp).toLocaleString(), val.id]
            values.push(entry)
            return '';
          })

          this.setState({values: values})
          if(this.state.values !== ''){
            this.setState({loading: false})
            clearInterval(interval);
          }
        }
      },100)
  }

  componentDidMount(){
    this.getData()
  }

  render(){

    const columns = [
      {
        name: "Issued To",
        options:{
          filter:true,
        }
      },
      {
        name: "Id",
        options:{
          filter:true,
        }
      },
      {
        name: "No of Products",
        options:{
          filter:true,
        }
      },
      {
        name: "Issued By",
        options:{
          filter:true,
        }
      },
      {
        name: "Approved",
        options:{
          filter:false,
          customBodyRender: (value, tableMeta, updateValue)=>{
            return(
              <span>
              {tableMeta.rowData[4]?
                <DoneIcon color="primary"/>
                :
                <SmsFailedIcon color="primary"/>
              }
              </span>
            );
          }
        }
      },
      {
        name: "Checked out",
        options:{
          filter:false,
          customBodyRender: (value, tableMeta, updateValue)=>{
            return(
              <span>
              {tableMeta.rowData[5]?
                <DoneIcon color="primary"/>
                :
                <SmsFailedIcon color="primary"/>
              }
              </span>
            );
          }
        }
      },

      {
        name: "Returned",
        options:{
          filter:false,
          customBodyRender: (value, tableMeta, updateValue)=>{
            return(
              <span>
              {tableMeta.rowData[6]?
                <DoneIcon color="primary"/>
                :
                <SmsFailedIcon color="primary"/>
              }
              </span>
            );
          }
        }
      },
      {
        name: "Timestamp",
        options:{
          filter:true,
        }
      },
      {
        name: "Actions",
        options:{
          filter:false,
          customBodyRender: (value, tableMeta, updateValue)=>{
            return(
              <Button color="primary" onClick={()=>this.handleView(tableMeta.rowData[8])}>
                <VisibilityIcon/>
              </Button>
            );
          }

        }
      },
    ];

    const options={
      filterType: 'textField',
      responsive: 'scrollMaxHeight',
      rowsPerPageOptions: [5, 10, 100],
      rowsPerPage:5,
      pagination: true,
      selectableRowsHeader: false,
      selectableRows: "none"
    };


    return(
      <Fragment>
        {this.state.loading?
          <Spinner />
          :
          <Fragment>
          <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MUIDataTable
              title={"Recent Activity"}
              columns={columns}
              data={this.state.values}
              options={options}
             />
            </div>
          </Fragment>
        }
        <Dialog
          fullWidth
          maxWidth="xs"
          open={this.state.more}
          onClose={this.handleClose}
          aria-labelledby="more-info"
          PaperComponent={PaperComponent}
          scroll="paper"
        >
          <DialogTitle id="more-info" style={{ cursor: 'move' }}>
            {"More..."}
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText component='div' >
              {this.state.currentData === ''?
                <Typography component="div" align="center">
                  <Spinner />
                </Typography>
              :
                <div className={this.props.classes.list}>
                  <Typography variant="body2">{this.state.currentData[0].issue_comment}</Typography>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                      <ListItemIcon>
                        <DevicesOtherIcon />
                      </ListItemIcon>
                      <ListItemText primary="Product(s)"/>
                    </ListItem>
                    <Divider />
                    {this.state.currentData[0].product.map(val =>{
                      return(
                        <ListItem key={val.id}>
                          <ListItemText primary={`${val.type}  serial: ${val.serial_number}`}/>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={()=>this.handleClose()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
export default withStyles(styles)(Transactions);
