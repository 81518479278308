import React, {Component} from 'react';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import Navigator from './common/Navigator';
import Header from './common/Header';
import Content from './Content';
import Footer from './common/Footer';
import Spinner from './common/Spinner'
import {profileService} from '../_services/profileService';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
  spinner:{
    zIndex: 1000,
    padding: 5
  }
};


class Home extends Component{
  constructor(props){
    super(props)
    this.setMobileOpen = this.setMobileOpen.bind(this)
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    this.setPage = this.setPage.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.setSubpage = this.setSubpage.bind(this)
    this.handleSubpage = this.handleSubpage.bind(this)
    this.state={
      mobileOpen:false,
      loading: true,
      profile:'',
      page:'dashboard',
      subpage:'home',
      tabVal: 0,
    }
  }

  setMobileOpen(){
    this.setState( (state)=>({
      mobileOpen : !state.mobileOpen
    }));
  };

  handleDrawerToggle(){
    this.setMobileOpen()
  }

  setSubpage(val1, val2){
    this.setState(()=>({
      subpage: val1,
      tabVal: val2
    }))
  }

  handleSubpage(val1, val2){
    this.setSubpage(val1, val2);
  }

  setPage(val){
    this.setMobileOpen();
    if(val==='dashboard'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'home'
      }))
    }

    if(val==='issue'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'add-product'
      }))
    }

    if(val==='approve'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'approve'
      }))
    }

    if(val==='user-accounts'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'add-user'
      }))
    }

    if(val==='organisations'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'add-org'
      }))
    }
    if(val==='checkout'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'org_prod'
      }))
    }
    if(val==='checkin'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'returned'
      }))
    }

    if(val==='security-account'){
      this.setState(state=>({
        page: val,
        tabVal: 0,
        subpage:'add-security'
      }))
    }
  }

  handlePageChange(val){
      if(typeof(val)!=='object'){
        this.setPage(val)
      }
  }

  componentDidMount(){
    profileService.profile()
      .then(val =>{
        if (val==='OK'){
          this.setState(state=>({
            profile: JSON.parse(localStorage.getItem('profile'))
          }))
        }
      })
      .catch(err=>{
        alert(err)
      })

      const interval = setInterval( ()=>{
        if(this.state.profile !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },500)
  }

  render(){

    return(
      <ThemeProvider theme={theme}>

      {this.state.loading ?
        <Spinner />
        :


        <div className={this.props.classes.root}>
          <CssBaseline />
          <nav className={this.props.classes.drawer} >
            <Hidden smUp implementation='js'>
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant='temporary'
              open={this.state.mobileOpen}
              onClose={()=>this.handleDrawerToggle()}
              profile={this.state.profile}
              onClick={this.handlePageChange}
            />
            </Hidden>
            <Hidden xsDown implementation='css'>
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                profile={this.state.profile}
                onClick={this.handlePageChange}
                />
            </Hidden>
          </nav>
          <div className={this.props.classes.app}>
            <Header
              onDrawerToggle={()=> this.handleDrawerToggle()}
              profile={this.state.profile}
              page={this.state.page}
              tabVal={this.state.tabVal}
              onClick={this.handleSubpage}
              />
            <main className={this.props.classes.main}>
              <Content profile={this.state.profile} page={this.state.page} subpage={this.state.subpage}/>
            </main>
            <footer className={this.props.classes.footer}>
             <Footer />
            </footer>
          </div>
        </div>
      }
      </ThemeProvider>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Home)
