import React, {Fragment, Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import AddProductForm from './AddProductForm';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2)
  },
  formControl:{
    margin: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon:{
    marginRight: 5,
  }
})


class AddProduct extends Component{
  constructor(props){
    super(props)
    this.state={

    }
  }

  render(){
    return(
      <Fragment>
        <Paper className={this.props.classes.paper}>
          <AddProductForm  classes={this.props.classes} loading={this.props.loading} prototypes={this.props.prototypes}/>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(AddProduct);
