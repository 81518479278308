import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import login from '../../static/login.jpg';
import LoginForm from './LoginForm';
import {history} from '../../_helpers/history';

import {authenticationService} from '../../_services/authenticationService';

const styles = theme=> ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${login})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  snackbar:{
    color: '#ff0000'
  }

});


class Login extends Component{
  constructor(props){
    super(props)
    this.state={
      loggedout: true
    }
    if(authenticationService.currentUserValue){
      history.push('/');
    }
  }
  componentDidMount(){
  }

  render(){
    return(
      <Fragment>
      <Grid container component='main' className={this.props.classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={this.props.classes.image}/>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
          <div className={this.props.classes.paper}>
            <Typography variant='h4'>
              Jamii Gatepass
            </Typography>
            <Avatar className={this.props.classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Login
            </Typography>
            <LoginForm  classes={this.props.classes}/>
          </div>
        </Grid>
      </Grid>
    </Fragment>
    )
  }
}
export default withStyles(styles)(Login)
