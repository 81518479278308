import React, {Component, Fragment} from 'react';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel  from '@material-ui/core/InputLabel';
import Input  from '@material-ui/core/Input';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import {organisationService} from '../../../_services/organisationService';

class OrgForm extends Component{
  constructor(props){
    super(props);
    this.setSuccess = this.setSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state={
      success: false,
      open:false,
      data:''
    }
  }

  setSuccess(val){
    if(this.state.success === false){
      this.setState((state)=>({
        success: val,
        open:true,
        data: this.props.status.data
      }))
    }
  }
  handleClose(){
    this.setState((state)=>({
      open:false,
      success: false
    }))
  }
  componentDidUpdate(){
    if(this.props.status!==undefined){
      this.setSuccess(this.props.status.success)
    }
  }
  render(){
    return(
      <Fragment>
      {this.state.success === true ?
        <Snackbar
          message={
            <div id='message-id'>
              Organisation Added
              <p>id: {this.state.data.id}</p>
              <p>name: {this.state.data.org_name}</p>
              <p>org_id: {this.state.data.org_id}</p>
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }
      <form onSubmit={this.props.handleSubmit}>
        <FormControl fullWidth={true} className={this.props.classes.formControl}>
          <InputLabel htmlFor="my-input">Organisation Name</InputLabel>
          <Input
            id="org_name"
            error={(this.props.touched.org_name && this.props.errors.org_name)? true : false }
            aria-describedby="my-helper-text"
            name='org_name'
            value={this.props.values.org_name || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            />
          <FormHelperText id="my-helper-text">Name of the organisation</FormHelperText>
        </FormControl>

        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.org_id && this.props.errors.org_id)? true : false }
          >
          <InputLabel htmlFor="my-input">Organisation id</InputLabel>
          <Input
            id="org_id"
            aria-describedby="my-helper-text"
            name='org_id'
            value={this.props.values.org_id || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            />
          <FormHelperText id="my-helper-text">{(this.props.touched.org_id && this.props.errors.org_id)? this.props.errors.org_id : 'Unique Id'}</FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={this.props.classes.submit}
        >
          <AddIcon />
          Add Organisation
        </Button>
      </form>
      </Fragment>
    );
  }
}

export default withFormik({
  mapPropsToValues: ()=>({
    org_name:'',
    org_id:''
  }),
  validationSchema: Yup.object().shape({
    org_name: Yup.string().min(3, 'Should be atleast 3 characters').required('Required!!'),
    org_id: Yup.string().min(3, 'Should be atleast 3 characters').required('Required!!')
  }),
  handleSubmit: (values, {resetForm, setErrors, setStatus, setSubmitting })=>{
    organisationService.addOrganisation(values.org_name, values.org_id)
      .then(data =>{
        if(data !== ''){
          setStatus({ success: true, data:data });
          setSubmitting(true);
          resetForm();
        }else{
          setSubmitting(false);
        }
      })
      .catch(error =>{
        setSubmitting(false);
        if(error.message === 'Request failed with status code 400'){
          setErrors({org_id: 'already exists'})
        }
      })
  }

})(OrgForm)
