import React, {Component, Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import OrgForm from './orgForm';



//import Spinner from '../../common/Spinner'
const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2)
  },
  formControl:{
    margin: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})



class AddOrganisation extends Component{
  constructor(props){
    super(props)
    this.setSuccess = this.setSuccess.bind(this);
    this.state={
      submitted: true,
      success: false,
    }
  }

  setSuccess(val){
    this.setState((state)=>({
      success: val
    }))
  }

  render(){
    return(
      <Fragment >
        <Typography variant='subtitle1' color='primary'>
          Add Organisation
        </Typography>
        <Paper className={this.props.classes.paper}>
          <OrgForm classes={this.props.classes} setSuccess={()=>this.setSuccess()}/>
        </Paper>

      </Fragment>
    );
  }
}
export default withStyles(styles)(AddOrganisation);
