import React, {Fragment, Component} from 'react';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel  from '@material-ui/core/InputLabel';
import Input  from '@material-ui/core/Input';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import {issueService} from '../../../_services/issueService';
import {productService} from '../../../_services/productService';


class IssueProductForm extends Component{
  constructor(props){
    super(props)
    this.setSuccess = this.setSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setError = this.setError.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.state={
      success: false,
      error: false,
      open: false,
      data:'',
      count: 0,
      loading: true,
      products: '',
      prototypes:'',
      options:''
    }
  }
  setError(val){
    if(this.state.error === false){
      this.setState({
        error: val,
        open: true,
        success: false,
        count: 1,
      });
    }
  }

  setSuccess(val){
    if(this.state.success === false){
      this.setState((state)=>({
        success: val,
        error: false,
        open:true,
        data: this.props.status.data,
      }))
    }
  }
  handleClose(){
    this.setState((state)=>({
      open:false,
      success: false,
      error: false,
      count: 0
    }))
    this.props.setStatus({error: undefined, success:undefined})
  }

  getProducts(){
    productService.getProducts()
      .then(val=>{
        this.setState(state=>({
          products: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      productService.getPrototypes()
        .then(val=>{
          this.setState(state=>({
            prototypes: val
          }))
        })
        .catch(error =>{
          alert(error)
        })
      const interval = setInterval( ()=>{
        if(this.state.products !== '' && this.state.prototypes !== ''){
          const values = []
          this.state.products.map( product => {
            const prototype = this.state.prototypes.find(prototype => prototype.id === product.type)
            const entry={value: product.id, label:`${prototype.product_brand} ${prototype.product_type},  ${prototype.model} : ${product.serial_number}`}
            values.push(entry);
            return ''
          })
          this.setState({options: values})
          if(this.state.options !== ''){
            this.setState({loading: false})
            clearInterval(interval);
          }
        }
      },100)
  }

  componentDidUpdate(){
    if(this.props.status!==undefined){
      if(this.props.status.success !== undefined){
        this.setSuccess(this.props.status.success)
      }
      if(this.props.status.error !== undefined){
        if(this.state.count === 0){
          this.setError(this.props.status.error)
        }

      }
    }
  }

  componentDidMount(){
    this.getProducts()
  }

  render(){
    const animatedComponents = makeAnimated();
    return(
      <Fragment>
      {this.state.success === true ?
        <Snackbar
          message={
            <div id='message-id'>
              Successfully issued product
              <p>Click status to view transaction status</p>
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }
      {this.state.error === true ?
        <Snackbar
          message={
            <div id='message-id'>
              {this.props.status.message}
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }
      <form onSubmit={this.props.handleSubmit}>

        <Typography
          align='left'
          color='primary'
          display='block'
          gutterBottom={true}
          variant='subtitle1'
        >
          Issue To
        </Typography>

        <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error={(this.props.touched.first_name && this.props.errors.first_name)? true : false }
          >
          <InputLabel htmlFor="first_name">First Name</InputLabel>
          <Input
            id="first_name"
            name="first_name"
            aria-describedby="my-helper-text"
            required={true}
            value={this.props.values.first_name || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">Person to be issued</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error={(this.props.touched.last_name && this.props.errors.last_name)? true : false }
          >
          <InputLabel htmlFor="last_name">Last Name</InputLabel>
          <Input
            id="last_name"
            name="last_name"
            aria-describedby="my-helper-text"
            required={true}
            value={this.props.values.last_name || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">Person to be issued</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error={(this.props.touched.title && this.props.errors.title)? true : false }
          >
          <InputLabel htmlFor="title">Title</InputLabel>
          <Input
            id="title"
            name="title"
            aria-describedby="my-helper-text"
            required={true}
            value={this.props.values.title || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">ie, employee, visitor, driver</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error={(this.props.touched.person_id && this.props.errors.person_id)? true : false }
          >
          <InputLabel htmlFor="person_id">Id</InputLabel>
          <Input
            id="person_id"
            name="person_id"
            aria-describedby="my-helper-text"
            required={true}
            value={this.props.values.person_id || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">ie, national Id, job Id</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error={(this.props.touched.products && this.props.errors.products)? true : false }
        >
          <Select
            isMulti
            isLoading={this.state.loading}
            components={animatedComponents}
            closeMenuOnSelect={false}
            options={this.state.loading? null : this.state.options}
            value={this.props.values.products || ''}
            onChange={event=>this.props.setFieldValue('products', event)}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">Select From Available Products</FormHelperText>
        </FormControl>

        <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error={(this.props.touched.comment && this.props.errors.comment)? true : false }
        >
          <TextField
            id='comment'
            name='comment'
            label='Add Comment'
            multiline
            rows='4'
            margin='normal'
            variant='outlined'
            value={this.props.values.comment}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">Comment</FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={this.props.classes.submit}
        >
          <SendIcon />
          Issue Product
        </Button>
      </form>
      </Fragment>
    );
  }
}

export default withFormik({
  mapPropsToValues:()=>({
    first_name:'',
    last_name:'',
    title:'',
    person_id:'',
    comment:'',
    products:''
  }),
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required('Required!!'),
    last_name: Yup.string().required('Required!!'),
    title: Yup.string().required('Required!!'),
    person_id: Yup.string().required('Required!!'),
    comment: Yup.string().required('Required!!'),
    products: Yup.array().min(1)
  }),
  handleSubmit: (values, {setSubmitting, setStatus, setErrors, resetForm})=>{
    issueService.issue(values)
      .then( data => {
        if(data !== ''){
          setStatus({ success: true, data:data });
          setSubmitting(true);
          resetForm();
        }else{
          setSubmitting(false);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if(err.message === 'Request failed with status code 400'){
          setStatus({error: true, message:err.message})
        }else{
          setStatus({error: true, message:err.message})
        }
      })
  }

})(IssueProductForm);
