import React, {Component, Fragment} from 'react';

import Home from './home';
import Transactions from './transactions';
import Profile from './profile';
import Organisation from './organisation';

class Dashboard extends Component{

  render(){
    const subpage = this.props.subpage;
    const profile = this.props.profile
    return(
      <Fragment>
        {subpage === 'home'?
          <Home profile={profile}/>
          :
          null
        }

        {subpage === 'transactions'?
          <Transactions profile={profile}/>
          :
          null
        }

        {subpage === 'profile'?
          <Profile profile={profile}/>
          :
          null
        }

        {subpage === 'organisation'?
          <Organisation profile={profile}/>
          :
          null
        }

      </Fragment>
    )
  }
}
export default Dashboard;
