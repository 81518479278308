import axios from  'axios';
import {authenticationService} from './authenticationService';

const report = async () =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/report`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}


const securityReport = async () =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/security_report`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}


const events = async () =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/events`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}


export const reportService={
  report,
  events,
  securityReport
}
