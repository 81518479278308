import React, {Component, Fragment} from 'react';
import Organisation from './Content/organisations/organisation.js';
import Dashboard from './Content/dashboard/dashboard';
import Users from './Content/users/users';
import Issue from './Content/issue/Issue';
import Approve from './Content/approve/Approve';
import Security from './Content/security/Security';

class Content extends Component{

  componentDidMount(){

  }

  render(){
    const {subpage, profile} = this.props
    return(
      <Fragment>
        {this.props.page === 'organisations' ?
          <Organisation subpage={subpage} profile={profile}/>
        :
          null
        }

        {this.props.page === 'dashboard' ?
          <Dashboard subpage={subpage} profile={profile}/>
        :
          null
        }
        {this.props.page === 'user-accounts' ?
          <Users subpage={subpage} profile={profile}/>
        :
          null
        }

        {this.props.page === 'issue' ?
          <Issue subpage={subpage} profile={profile}/>
        :
          null
        }
        {this.props.page === 'approve' ?
          <Approve subpage={subpage} profile={profile}/>
        :
          null
        }
        {this.props.page === 'checkout' ?

          <Security subpage={subpage} profile={profile} page={this.props.page}/>
        :
          null
        }

        {this.props.page === 'checkin' ?

          <Security subpage={subpage} profile={profile} page={this.props.page}/>
        :
          null
        }
        {this.props.page === 'security-account' ?

          <Security subpage={subpage} profile={profile} page={this.props.page}/>
        :
          null
        }


      </Fragment>
    )
  }

}

export default Content
