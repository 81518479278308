import axios from  'axios';
import {authenticationService} from './authenticationService';

const issuedList = async () =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/approve`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const approve = async (id) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.put(`${api_url}/api/v1/approve/${id}`,{approve: true},{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

export const approveService = {
  issuedList,
  approve
}
