import React, {Fragment, Component} from 'react';
import * as Yup from 'yup';
import {securityService} from '../../../../_services/securityService';
import {withFormik} from 'formik';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel  from '@material-ui/core/InputLabel';
import Input  from '@material-ui/core/Input';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';



class AddAccountForm extends Component{
  constructor(props){
    super(props)
    this.setSuccess = this.setSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setError = this.setError.bind(this);
    this.state={
      success: false,
      error: false,
      open: false,
      data:'',
      count: 0
    }
  }

  setError(val){
    if(this.state.error === false){
      this.setState({
        error: val,
        open: true,
        success: false,
        count: 1,
      });
    }
  }

  setSuccess(val){
    if(this.state.success === false){
      this.setState((state)=>({
        success: val,
        error: false,
        open:true,
        data: this.props.status.data,
      }))
    }
  }

  handleClose(){
    this.setState((state)=>({
      open:false,
      success: false,
      error: false,
      count: 0
    }))
    this.props.setStatus({error: undefined, success:undefined})
  }

  componentDidUpdate(){
    if(this.props.status!==undefined){
      if(this.props.status.success !== undefined){
        this.setSuccess(this.props.status.success)
      }
      if(this.props.status.error !== undefined){
        if(this.state.count === 0){
          this.setError(this.props.status.error)
        }

      }
    }
  }


  render(){
    return(
      <Fragment>

      {this.state.success === true ?
        <Snackbar
          message={
            <div id='message-id'>
              User Created
              <p>username: {this.state.data.username}</p>
              <p>email: {this.state.data.email}</p>
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }

      {this.state.error === true ?
        <Snackbar
          message={
            <div id='message-id'>
              {this.props.status.message}
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }

      <form onSubmit={this.props.handleSubmit}>
      <FormControl
        fullWidth={true}
        className={this.props.classes.formControl}
        error={(this.props.touched.username && this.props.errors.username)? true : false }
        >
        <InputLabel htmlFor="username">Username</InputLabel>
        <Input
          id="username"
          name="username"
          aria-describedby="my-helper-text"
          required={true}
          value={this.props.values.username || ''}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          />
        <FormHelperText id="my-helper-text">User's Username</FormHelperText>
      </FormControl>

      <FormControl
        fullWidth={true}
        className={this.props.classes.formControl}
        error={(this.props.touched.user_id && this.props.errors.user_id)? true : false }
        >
        <InputLabel htmlFor="userId">UserId</InputLabel>
        <Input
          id="userId"
          name='user_id'
          aria-describedby="my-helper-text"
          required={true}
          value={this.props.values.user_id || ''}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          />
        <FormHelperText id="my-helper-text">i.e national ID, job Id</FormHelperText>
      </FormControl>

      <FormControl
        fullWidth={true}
        className={this.props.classes.formControl}
        error={(this.props.touched.email && this.props.errors.email)? true : false }
        >
        <InputLabel htmlFor="email">Email</InputLabel>
        <Input
          id="email"
          name='email'
          aria-describedby="my-helper-text"
          required={true}
          type='email'
          value={this.props.values.email || ''}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          />
        <FormHelperText id="my-helper-text">Unique and valid email address</FormHelperText>
      </FormControl>

      <FormControl
        fullWidth={true}
        className={this.props.classes.formControl}
        error={(this.props.touched.password && this.props.errors.password)? true : false }
        >
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          id="password"
          name='password'
          aria-describedby="my-helper-text"
          required={true}
          type='password'
          value={this.props.values.password || ''}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          />
        <FormHelperText id="my-helper-text">Password</FormHelperText>
      </FormControl>

      <FormControl
        fullWidth={false}
        className={this.props.classes.formControl}
        error={(this.props.touched.first_name && this.props.errors.last_name)? true : false }
        >
        <InputLabel htmlFor="first_name">First Name</InputLabel>
        <Input
          id="first_name"
          name='first_name'
          aria-describedby="my-helper-text"
          required={true}
          type='text'
          value={this.props.values.first_name || ''}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          />
        <FormHelperText id="my-helper-text">User's first name</FormHelperText>
      </FormControl>

      <FormControl
        fullWidth={false}
        className={this.props.classes.formControl}
        error={(this.props.touched.last_name && this.props.errors.last_name)? true : false }
        >
        <InputLabel htmlFor="last_name">Last Name</InputLabel>
        <Input
          id="last_name"
          name='last_name'
          aria-describedby="my-helper-text"
          required={true}
          type='text'
          value={this.props.values.last_name || ''}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          />
        <FormHelperText id="my-helper-text">User's last name</FormHelperText>
      </FormControl>
      <Typography
        align='left'
        color='primary'
        display='block'
        gutterBottom={true}
        variant='subtitle1'
      >
        Permissions
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id='org-admin'
              name='organisation_admin'
              color="primary"
              value={this.props.values.organisation_admin || false}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              checked={this.props.values.organisation_admin || false}
            />
          }
          label={`${this.props.profile.organisation} Admin`}
        />
      </FormGroup>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={this.props.classes.submit}
      >
        <AddIcon />
        Create User
      </Button>
      </form>

      </Fragment>
    );
  }
}

export default withFormik({
  mapPropsToValues: ()=>({
    username:'',
    user_id:'',
    email:'',
    password:'',
    first_name:'',
    last_name:'',
    organisation_admin:false
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().min(3, 'Should be atleast 3 characters').required('Required!!'),
    user_id:Yup.string().min(3, 'Should be atleast 3 characters').required('Required!!'),
    email: Yup.string().required('Required!!'),
    password: Yup.string().required('Required!!'),
    first_name: Yup.string().required('Required!!'),
    last_name: Yup.string().required('Required!!')
  }),
  handleSubmit: (values, {setSubmitting, setStatus, setErrors, resetForm, props})=>{
    securityService.addSecurity(values)
      .then( data => {
        if(data !== ''){
          setStatus({ success: true, data:data });
          setSubmitting(true);
          resetForm();
        }else{
          setSubmitting(false);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if(err.message === 'Request failed with status code 400'){
          setStatus({error: true, message:'Invalid email and/or userId'})
        }else{
          setStatus({error: true, message:err.message})
        }
      })

  }
})(AddAccountForm);
