import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import personIcon from '../../../static/person-icon.png';
import ProfileUpdateForm from './profileUpdateForm';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),

  },
  image:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
  },
  info:{
    marginLeft: 10,
    color: '#DEB887'
  },
  span:{
    marginRight:10,
    color:'#2F4F4F'
  },
  formControl:{
    margin: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon:{
    marginRight: 5,
  },
  card: {
    marginTop: 10,
    maxWidth: 345,
    padding: theme.spacing(3, 2)
  },
  media: {
    height: 140,
  },
})
class Profile extends Component{
  render(){
    return(
      <Fragment>
        <Grid container direction="row" spacing={2}>
          <Grid item sm={4}>
            <Card className={this.props.classes.card}>
              <CardActionArea>
                <CardMedia
                 className={this.props.classes.media}
                 image={personIcon}
                 title={this.props.profile.username}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" align="center">
                    {this.props.profile.username}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="div" align="center">
                    <p>Full Name: {this.props.profile.first_name} {this.props.profile.last_name}</p>
                    <p>Organisation/Department: {this.props.profile.organisation}</p>
                    <p>User Id: {this.props.profile.user_id}</p>
                    <p>Email: {this.props.profile.email}</p>
                    <p>
                      {this.props.profile.system_admin ?
                        "Role: System Admin"
                        :
                        <Fragment>
                          {this.props.profile.organisation_admin?
                            "Role: Organization/Department Admin"
                            :
                            null
                          }
                        </Fragment>
                      }
                    </p>
                    <p>{this.props.profile.security? "Role: Security": null}</p>
                    <p>{this.props.profile.can_issue?"Permission: Can Issue Product" : null}</p>
                    <p>{this.props.profile.can_approve?"Permission: Can Approve Transaction" : null}</p>


                  </Typography>
                </CardContent>
              </CardActionArea>

            </Card>
          </Grid>
          <Grid item sm={8}>
            <Paper className={this.props.classes.paper}>
              <ProfileUpdateForm profile={this.props.profile} classes={this.props.classes}/>
            </Paper>
          </Grid>
        </Grid>

      </Fragment>
    );
  }
}
export default  withStyles(styles)(Profile);
