import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Footer from '../common/Footer';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

//calling login service
import {authenticationService} from '../../_services/authenticationService';


class LoginForm extends Component{
  constructor(props){
      super(props)
      this.setError = this.setError.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handlePassReset = this.handlePassReset.bind(this);
      this.state={
        checked: false,
        error: false,
        open:false,
        count: 0,
        passReset: false
      }
  }

  handlePassReset(val){
    this.setState((state)=>({
      passReset: val
    }));
  };

  handleChange(event){
    this.setState(state=>({
      checked: !state.checked
    }))
  }

  setError(val){
    if(this.state.error === false){
      this.setState((state)=>({
        error: val,
        open:true,
      }))
    }
  }

  handleClose(){
    this.setState((state)=>({
      open:false,
      error: false
    }))
    this.props.setStatus({error:false})
  }

  componentDidUpdate(){
    if(this.props.status !== undefined){
      if(this.props.status.error === true){
        this.setError(this.props.status.error)
      }
    }
  }

  render(){
    return(
      <Fragment>
      {this.state.error === true ?
        <Snackbar
          message={
            <span
              className={this.props.classes.snackbar}
            >Invalid Email and/or Password!!! </span>
          }
          anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }
      <form onSubmit={this.props.handleSubmit}>
        <TextField
          error={this.props.errors.email && this.props.touched.email? true : false}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={this.props.values.email}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
        />

        <TextField
          error={this.props.errors.password && this.props.touched.password}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={this.state.checked? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          value={this.props.values.password}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name='valid'
            checked={this.state.checked}
            onClick={(event)=>this.handleChange(event)}
            />
          }
        label="Show"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={this.props.classes.submit}
      >
        Sign In
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href='#' variant='body2' onClick={(event)=>this.handlePassReset(true)}>
            Forgot Password?
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Footer />
      </Box>
      </form>
      <Dialog
        fullWidth={true}
        onClose={()=>this.handlePassReset(false)}
        open={this.state.passReset}
        aria-labelledby="pass-reset-dialog"
      >
      <DialogTitle id="pass-reset-dialog">
        Reset Password
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To reset your password, please enter your email address associated with the account here.
          A new password will be created and emailed to you
        </DialogContentText>
        <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="valid_email"
        label="Email"
        type="email"
        >

        </TextField>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={()=>this.handlePassReset(false)}>Cancel</Button>
        <Button color="primary">Send Request</Button>
      </DialogActions>
      </Dialog>
      </Fragment>
    );
  }
}
export default withFormik({
  mapPropsToValues: ()=>({
    email:'',
    password:'',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Invalid!!').required('Required!!'),
    password: Yup.string().min(6,'too short').required('Required!!')
  }),
  handleSubmit: (values, {setSubmitting, setStatus, setErrors}) => {
    authenticationService.login(values.email, values.password)
      .then(val =>{
        console.log(val)
      })
      .catch( err => {
        if(err.message==='Request failed with status code 401'){
          setStatus({error:true})
          setErrors({email:'invalid', password:'invalid'})
        }
        else{
          alert(err)
        }
      })
  }
})(LoginForm)
