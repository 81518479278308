import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from  '@material-ui/core/Drawer';
import List from  '@material-ui/core/List';
import ListItem from  '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import BusinessIcon from '@material-ui/icons/Business';
import SecurityIcon from '@material-ui/icons/Security';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
    color: 'white',
  },
});

class Navigator extends Component{
  constructor(props){
    super(props)
    this.changePage = this.changePage.bind(this)
    this.state={
    }
  }
   changePage(val){
     console.log(val);
   }

  render(){
    const profile = this.props.profile
    const {classes, ...other} = this.props
    const { onClick } = this.props
    return(
      <Drawer variant='permanent' {...other}>
        <List disablePadding>
          <ListItem className={clsx(this.props.classes.firebase, this.props.classes.item, this.props.classes.itemCategory)}>
            Jamii Towers
          </ListItem>
          <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
            <ListItemIcon className={this.props.classes.itemIcon}>
                <HomeIcon color="primary"/>
            </ListItemIcon>
            <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
              {profile.organisation=== null ?
                <span>Home</span>
                :
                <Typography variant="subtitle1" color="primary">
                {profile.organisation}
                </Typography>
              }
            </ListItemText>
          </ListItem>

          <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
            <ListItemIcon className={this.props.classes.itemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
              <Button className={this.props.classes.button} onClick={()=>onClick('dashboard')}>
                Dashboard
              </Button>
            </ListItemText>
          </ListItem>

          {profile.can_issue ?
            <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
              <ListItemIcon className={this.props.classes.itemIcon}>
                <ImportantDevicesIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
                <Button className={this.props.classes.button} onClick={()=>onClick('issue')}>
                  Issue product/device
                </Button>
              </ListItemText>
            </ListItem>

          :
          null
        }
        {profile.can_approve ?
          <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
            <ListItemIcon className={this.props.classes.itemIcon}>
              <ConfirmationNumberIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
              <Button className={this.props.classes.button} onClick={()=>onClick('approve')}>
                Approve
              </Button>
            </ListItemText>
          </ListItem>

        :
        null
      }
      {(profile.organisation_admin || profile.system_admin) && (!profile.security) ?
        <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
          <ListItemIcon className={this.props.classes.itemIcon}>
            <RecentActorsIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
            <Button className={this.props.classes.button} onClick={()=>onClick('user-accounts')}>
              Manage User Accounts
            </Button>
          </ListItemText>
        </ListItem>

      :
      null
    }
    {profile.system_admin ?
      <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
        <ListItemIcon className={this.props.classes.itemIcon}>
          <BusinessIcon/>
        </ListItemIcon>
        <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
          <Button className={this.props.classes.button} onClick={()=>onClick('organisations')}>
            Organisations
          </Button>
        </ListItemText>
      </ListItem>
    :
    null
  }
  {profile.security ?
    <Fragment>

      <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
      <ListItemIcon className={this.props.classes.itemIcon}>
        <SecurityIcon/>
      </ListItemIcon>
      <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
        <Button className={this.props.classes.button} onClick={()=>onClick('checkout')}>
          Checkout
        </Button>
      </ListItemText>
    </ListItem>

    <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
      <ListItemIcon className={this.props.classes.itemIcon}>
        <SecurityIcon/>
      </ListItemIcon>
      <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
        <Button className={this.props.classes.button} onClick={()=>onClick('checkin')}>
          Checkin
        </Button>
      </ListItemText>
    </ListItem>

    {profile.organisation_admin?
      <ListItem className={clsx(this.props.classes.item, this.props.classes.itemCategory)}>
      <ListItemIcon className={this.props.classes.itemIcon}>
        <SecurityIcon/>
      </ListItemIcon>
      <ListItemText classes={{ primary: this.props.classes.categoryHeaderPrimary}}>
        <Button className={this.props.classes.button} onClick={()=>onClick('security-account')}>
          Manage Accounts
        </Button>
      </ListItemText>
    </ListItem>
    :
    null
    }

    </Fragment>

  :
  null
}
        </List>
      </Drawer>

    )
  }
}
export default withStyles(styles)(Navigator)
