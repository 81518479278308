import React, {Component, Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import Spinner from '../../common/Spinner';
import {reportService} from '../../../_services/reportService';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
  },
  card1: {
    maxWidth: 345,
    backgroundColor: red[100]
  },
  card2: {
    maxWidth: 345,
    backgroundColor: blue[100]
  },
  avatar: {
    backgroundColor: red[500],
  },
  card: {
    maxWidth: 345,
    //backgroundColor: blue[100]
  },
})

class Home extends Component{
  constructor(props){
    super(props)
    this.state={
      loading: true,
      data: ''
    }
  }

  getReport(){
    reportService.report()
      .then(val=>{
        this.setState(state=>({
          data: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.data !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }

  getSecurityReport(){
    reportService.securityReport()
      .then(val=>{
        this.setState(state=>({
          data: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.data !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }

  componentDidMount(){
    if(this.props.profile.security){
      this.getSecurityReport()
    }else{
      this.getReport()
    }

  }

  render(){
    const profile =this.props.profile;
    return(
      <Fragment>
      {this.state.loading?
        <Spinner />
      :
      <Fragment>
      <Typography variant='subtitle1' display='block' color='primary'>
        Welcome <span style={{textTransform: 'uppercase'}}>{profile.username}</span>
      </Typography>
      {profile.security ?
        <Fragment>

        {this.state.data.length === 0 ?
          <Typography variant="body2" display="block" color="dark">
            No Transactions set for checkout
          </Typography>
          :
          null
        }

        <Grid container justify='flex-start' alignItems='center' spacing={4}>

          {this.state.data.map(val =>{
            return(
              <Grid item xs key={val.organisation}>

                  <Card className={this.props.classes.card} raised={true}>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className={this.props.classes.avatar}>
                            {val.organisation.split('')[0]}
                          </Avatar>
                        }
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={val.organisation}
                        subheader={new Date().toLocaleDateString()}
                      />
                      <CardContent>
                        <Typography variant="body1" color="textPrimary" component="h4">
                          {val.total + " pending checkouts"}
                        </Typography>
                      </CardContent>
                  </Card>

              </Grid>
            );
          })}


        </Grid>
        </Fragment>
        :
        <Grid container justify='flex-start' alignItems='center' spacing={6}>
          <Grid item xs>
            <Paper className={this.props.classes.paper}>
              <Card
                className={this.state.data.available_products===0 ? this.props.classes.card2 : this.props.classes.card1 }
                raised={true}
                >
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={this.props.classes.avatar}>
                        {this.state.data.organisation.split('')[0]}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Available Products"
                    subheader={new Date().toLocaleString()}
                  />
                  <CardContent>
                    <Typography variant="h3" color="textPrimary" component="h3">
                      {this.state.data.available_products}
                    </Typography>
                  </CardContent>
              </Card>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper className={this.props.classes.paper}>
              <Card
                className={this.state.data.pending_approvals===0 ? this.props.classes.card2 : this.props.classes.card1 }
                raised={true}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={this.props.classes.avatar}>
                        {this.state.data.organisation.split('')[0]}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Pending Approvals"
                    subheader={new Date().toLocaleString()}
                  />
                  <CardContent>
                    <Typography variant="h3" color="textPrimary" component="h3">
                      {this.state.data.pending_approvals}
                    </Typography>
                  </CardContent>
              </Card>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper className={this.props.classes.paper}>
              <Card
                className={this.state.data.pending_checkout===0 ? this.props.classes.card2 : this.props.classes.card1 }
                raised={true}
                >
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={this.props.classes.avatar}>
                        {this.state.data.organisation.split('')[0]}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Pending Checkouts"
                    subheader={new Date().toLocaleString()}
                  />
                  <CardContent>
                    <Typography variant="h3" color="textPrimary" component="h3">
                      {this.state.data.pending_checkout}
                    </Typography>
                  </CardContent>
              </Card>
            </Paper>
          </Grid>

        </Grid>
      }
      </Fragment>
      }
      </Fragment>
    );
  }
}
export default withStyles(styles)(Home);
