import React, {Component} from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import Login from './components/Login/Login';
import Home from './components/Home';
import {history} from './_helpers/history';
import {PrivateRoute } from './_helpers/privateRoute';

class App extends Component{

  render(){
    return(
      <Router history={history}>
        <Switch>
          <Route path='/login' exact component={Login}/>
          <PrivateRoute path='/' exact component={Home}/>
          <Redirect to='/' />
        </Switch>
      </Router>
    )
  }
}

export default App;
