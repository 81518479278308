import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import AddUserForm from './AddUserForm';
import AddAdminUserForm from './AddAdminUserForm';


const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2)
  },
  formControl:{
    margin: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon:{
    marginRight: 5,
  }
})

class AddUser extends Component{
  constructor(props){
    super(props)
    this.setValue = this.setValue.bind(this);
    this.state={
      systemAdmin: this.props.profile.system_admin,
      value:0,
    }
  }
  setValue(val){
    this.setState({value:val})
  }

  componentDidMount(){

  }

  render(){
    return(
      <Fragment>
        {this.state.systemAdmin ?
          <BottomNavigation
            value={this.state.value}
            showLabels
            style={{backgroundColor:'inherit'}}
            >
            <BottomNavigationAction label="Create User"  icon={<PersonAddIcon />} onClick={()=>this.setValue(0)}/>
            <BottomNavigationAction label="Create Admin"  icon={<VerifiedUserIcon />} onClick={()=>this.setValue(1)}/>
          </BottomNavigation>
          :
          <Typography variant='subtitle1' color='primary'>
            <BottomNavigation
              value={this.state.value}
              showLabels
              style={{backgroundColor:'inherit'}}
            >
              <BottomNavigationAction label="Create User"  icon={<PersonAddIcon />} onClick={()=>this.setValue(0)}/>
            </BottomNavigation>
          </Typography>
        }

        <Paper className={this.props.classes.paper}>
          {this.state.value===0?
            <AddUserForm classes={this.props.classes} profile={this.props.profile}/>
          :
            <AddAdminUserForm classes={this.props.classes}/>
        }
        </Paper>

      </Fragment>
    );
  }
}

export default withStyles(styles)(AddUser);
