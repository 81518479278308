import React, {Fragment, Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel  from '@material-ui/core/InputLabel';
import Input  from '@material-ui/core/Input';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {withFormik} from 'formik';
import * as Yup from 'yup';

import {productService} from '../../../_services/productService';

class AddPrototypeForm extends Component{
  constructor(props){
    super(props)
    this.setError = this.setError.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state={
      success:false,
      open: false,
      error: false,
      loading: true,
      prototypes:'',
      count:0,
      data:'',
    }
  }

  setError(val){
    if(this.state.error === false){
      this.setState({
        error: val,
        open: true,
        success: false,
        count: 1,
      });
    }
  }

  setSuccess(val){
    if(this.state.success === false){
      this.setState((state)=>({
        success: val,
        error: false,
        open:true,
        data: this.props.status.data,
      }))
    }
  }
  handleClose(){
    this.setState((state)=>({
      open:false,
      success: false,
      error: false,
      count: 0
    }))
    this.props.setStatus({error: undefined, success:undefined})
  }
  componentDidUpdate(){
    if(this.props.status!==undefined){
      if(this.props.status.success !== undefined){
        this.setSuccess(this.props.status.success)
      }
      if(this.props.status.error !== undefined){
        if(this.state.count === 0){
          this.setError(this.props.status.error)
        }
      }
    }
  }

  componentDidMount(){
    const interval = setInterval( ()=>{
      if(this.props.loading === false){
        const prototypes = this.props.prototypes;
        const values = []
        prototypes.map( prototype =>{
          const entry = {label:`Type: ${prototype.product_type}  Brand: ${prototype.product_brand}  Model: ${prototype.model}`, value:prototype.id}
          values.push(entry)
          return ''
        });
        this.setState( state=> ({
          prototypes: values
        }))

        if(this.state.prototypes !==''){
          this.setState({loading: false })
          clearInterval(interval);
        }

      }

    },100)
  }

  render(){
    return(
      <Fragment>
      {this.state.success === true ?
        <Snackbar
          message={
            <div id='message-id'>
                Prototype Created
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }
      {this.state.error === true ?
        <Snackbar
          message={
            <div id='message-id'>
              {this.props.status.message}
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }

      <form onSubmit={this.props.handleSubmit}>
        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.type && this.props.errors.type)? true : false }
        >
          <InputLabel htmlFor="type">Type of Product</InputLabel>
          <Input
            id="type"
            name="type"
            value={this.props.values.type || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            aria-describedby="my-helper-text"
            required={true}
          />
          <FormHelperText id="my-helper-text">i.e Laptop, Mifi, Camera</FormHelperText>
        </FormControl>

        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.brand && this.props.errors.brand)? true : false }
        >
          <InputLabel htmlFor="brand">Brand</InputLabel>
          <Input
            id="brand"
            name="brand"
            value={this.props.values.brand || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            aria-describedby="my-helper-text"
            required={true}
          />
          <FormHelperText id="my-helper-text">i.e Hp, Huawei, Sony</FormHelperText>
        </FormControl>

        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.brand && this.props.errors.brand)? true : false }
        >
          <InputLabel htmlFor="model">Model</InputLabel>
          <Input
            id="model"
            name="model"
            value={this.props.values.model || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            aria-describedby="my-helper-text"
            required={true}
          />
          <FormHelperText id="my-helper-text">i.e HP Pavilion 15, Huawei E5770, Sony A6400 </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.color && this.props.errors.color)? true : false }
        >
          <InputLabel htmlFor="color">Colour</InputLabel>
          <Input
            id="color"
            name="color"
            value={this.props.values.color || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            aria-describedby="my-helper-text"
            required={true}
          />
          <FormHelperText id="my-helper-text">i.e grey, white, black </FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={this.props.classes.submit}
        >
          <AddIcon />
          Create Prototype
        </Button>
      </form>

      </Fragment>
    );
  }
}

export default withFormik({
  mapPropsToValues: ()=>({
    type:'',
    brand:'',
    model:'',
    color:''
  }),
  validationSchema: Yup.object().shape({
    type: Yup.string().required('Required!!'),
    brand: Yup.string().required('Required!!'),
    model: Yup.string().required('Required!!'),
    color: Yup.string().required('Required!!')
  }),
  handleSubmit: (values, {setSubmitting, setStatus, setErrors, resetForm})=>{
    productService.addPrototype(values)
      .then( data => {
        if(data !== ''){
          setStatus({success: true, data:data });
          setSubmitting(true);
          resetForm();
        }else{
          setSubmitting(false);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if(err.message === 'Request failed with status code 400'){
          setStatus({error: true, message:'An error occured'})
        }else{
          setStatus({error: true, message:err.message})
        }
      })
  }

})(AddPrototypeForm);
