import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Spinner from '../../common/Spinner';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';

import {approveService} from '../../../_services/approveService';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
    overflowX: 'auto',
  },
  tableWrapper:{
    marginTop: 10,
    maxHeight: 440,
    overflow: 'auto'
  },
  tableHead:{
    background: 'red',
  },
  popover:{
    padding: theme.spacing(3, 2),
    maxWidth: 400,
    maxHeight: 400,
    overflow: 'auto'
  }
})

class ApproveProduct extends Component{
  constructor(props){
    super(props)
    this.setPage = this.setPage.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.getIssuedProducts = this.getIssuedProducts.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state={
      loading:true,
      page:0,
      rowsPerPage:5,
      products:'',
      anchorEl: null,
      entry: ''
    }
  }
  setPage(val){
    this.setState({page: val})
  }
  setRowsPerPage(val){
    this.setState({rowsPerPage: val})
  }

  handleChangeRowsPerPage(event){
    this.setPage(0);
    this.setRowsPerPage(+event.target.value)
  }
  handleChangePage(event, newPage){
    this.setPage(newPage);
  }

  getIssuedProducts(){
    approveService.issuedList()
      .then(val=>{
        this.setState(state=>({
          products: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.products !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }

  handleApprove(id){
    console.log(id)
    approveService.approve(id)
      .then(data =>{
        this.getIssuedProducts()
        this.handleClose()
      })
      .catch(err=>{
        this.handleClose()
        alert(err)
      })

  }

  handleClick(event, entry){
    const target = event.currentTarget
    this.setAnchorEl(target)
    this.setState({entry:entry})
  }

  setAnchorEl(val){
    this.setState({anchorEl: val})
  }

  handleClose(){
    this.setAnchorEl(null)
  }


  componentDidMount(){
    this.getIssuedProducts();
  }

  render(){
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return(
      <Fragment>
      {this.state.loading?
        <Spinner />
      :
        <Fragment>
          <Paper className={this.props.classes.paper}>
            <Typography>Approve a product</Typography>
            <div className={this.props.classes.tableWrapper}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead className={this.props.classes.tableHead} >
                  <TableRow>
                    <TableCell>Issued To</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Issued By</TableCell>
                    <TableCell>No. of products</TableCell>
                    <TableCell>Time of Issue</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.products.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(entry =>{
                    return(
                      <TableRow key={entry.id}>
                        <TableCell>{`${entry.first_name} ${entry.last_name}`}</TableCell>
                        <TableCell>{entry.person_id}</TableCell>
                        <TableCell>{entry.issued_by}</TableCell>
                        <TableCell>{entry.product.length}</TableCell>
                        <TableCell>{new Date(entry.time_of_issue).toLocaleString()}</TableCell>
                        <TableCell>
                            <Chip
                            label='Approve'
                             color='secondary'
                             variant='outlined'
                             onClick={(event)=>this.handleClick(event, entry)}
                             avatar={<Avatar>....</Avatar>}
                            />
                        </TableCell>
                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.products.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{'aria-label': 'previous page',}}
              nextIconButtonProps={{'aria-label': 'next page',}}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
          <Popover
            id={id}
            open={open}
            anchorEl={this.state.anchorEl}
            onClose = {this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Paper className={this.props.classes.popover}>
              {this.state.entry === ''?
                <Typography variant='subtitle1' color='primary'>Loading...</Typography>
              :
                <Fragment>
                  <Typography variant='subtitle1' color='primary'>Approving</Typography>
                  <hr/>
                  <Typography variant='subtitle2'>Issued To: {`${this.state.entry.first_name} ${this.state.entry.last_name}`}</Typography>
                  <Typography variant='subtitle2'>Title: {this.state.entry.title}</Typography>
                  <Typography variant='subtitle2'>Id: {this.state.entry.person_id}</Typography>
                  <Typography variant='subtitle2'>Issued By: {this.state.entry.issued_by}</Typography>
                  <hr/>
                  <Typography variant='subtitle1' color='primary'>Comment</Typography>
                  <Typography variant='body1'>{this.state.entry.issue_comment}</Typography>
                  <hr/>
                  <Chip
                  label='Approve'
                   color='primary'
                   variant='outlined'
                   onClick={()=>this.handleApprove(this.state.entry.id)}
                  />

                </Fragment>
              }

            </Paper>
          </Popover>

        </Fragment>
      }
      </Fragment>
    );
  }
}

export default withStyles(styles)(ApproveProduct)
