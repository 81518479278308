import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Spinner from '../../common/Spinner';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

import {issueService} from '../../../_services/issueService';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
    overflowX: 'auto',
  },
  tableWrapper:{
    marginTop: 10,
    maxHeight: 440,
    overflow: 'auto'
  },
  tableHead:{
    background: 'red',
  }
})

class Status extends Component{
  constructor(props){
    super(props)
    this.setPage = this.setPage.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.getIssuedProducts = this.getIssuedProducts.bind(this);
    this.state={
      loading:true,
      page:0,
      rowsPerPage:5,
      products:''
    }
  }
  setPage(val){
    this.setState({page: val})
  }
  setRowsPerPage(val){
    this.setState({rowsPerPage: val})
  }

  handleChangeRowsPerPage(event){
    this.setPage(0);
    this.setRowsPerPage(+event.target.value)
  }
  handleChangePage(event, newPage){
    this.setPage(newPage);
  }

  getIssuedProducts(){
    issueService.issuedList()
      .then(val=>{
        this.setState(state=>({
          products: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.products !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)

  }

  componentDidMount(){
    this.getIssuedProducts();
  }

  render(){
    return(
      <Fragment>
      {this.state.loading?
        <Spinner />
      :
        <Fragment>
          <Paper className={this.props.classes.paper}>
            <Typography>View Status</Typography>
            <div className={this.props.classes.tableWrapper}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead className={this.props.classes.tableHead} >
                  <TableRow>
                    <TableCell>Issued To</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Issued By</TableCell>
                    <TableCell>No. of products</TableCell>
                    <TableCell>Issuer Comment</TableCell>
                    <TableCell>Approved</TableCell>
                    <TableCell>Checked out</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.products.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(entry =>{
                    return(
                      <TableRow key={entry.id}>
                        <TableCell>{`${entry.first_name} ${entry.last_name}`}</TableCell>
                        <TableCell>{entry.person_id}</TableCell>
                        <TableCell>{entry.issued_by}</TableCell>
                        <TableCell>{entry.product.length}</TableCell>
                        <TableCell>{entry.issue_comment}</TableCell>
                        <TableCell>
                          {entry.approve?
                            <Chip
                              color='primary'
                              variant='outlined'
                              avatar={<Avatar><DoneIcon /></Avatar>}
                              />
                            :
                            <Chip
                            label='Pending'
                             color='secondary'
                             variant='outlined'
                             avatar={<Avatar>....</Avatar>}
                            />
                          }

                        </TableCell>
                        <TableCell>
                          {entry.check_out ?
                            <Chip
                              color='primary'
                              variant='outlined'
                              avatar={<Avatar><DoneIcon /></Avatar>}
                              />
                            :
                            <Chip
                            label='Pending'
                             color='secondary'
                             variant='outlined'
                             avatar={<Avatar>....</Avatar>}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.products.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{'aria-label': 'previous page',}}
              nextIconButtonProps={{'aria-label': 'next page',}}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Fragment>
      }
      </Fragment>
    );
  }
}

export default withStyles(styles)(Status)
