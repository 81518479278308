import axios from  'axios';
import {BehaviorSubject} from 'rxjs';
import {authenticationService} from './authenticationService';
import {history} from '../_helpers/history';

const profile=async ()=>{
  const api_url = 'http://197.232.56.28:1010';
  let status = '';
  await axios.get(`${api_url}/api/v1/profile`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then( resp => {
    if(resp.statusText === 'OK'){
      localStorage.setItem('profile', JSON.stringify(resp.data))
      status = resp.statusText
      //return (resp.statusText)
    }
  })
  .catch( err =>{
    localStorage.removeItem('userToken');
    localStorage.removeItem('profile');
    localStorage.clear();
    history.push('/login')
  })
  return status
}

const editProfile = async(values) => {
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.put(`${api_url}/api/v1/profile`,{
    "email":values.email,
    "username":values.username,
    "user_id":values.user_id,
    "first_name":values.first_name,
    "last_name":values.last_name
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}


const userProfile = new BehaviorSubject(JSON.parse(localStorage.getItem('profile')));

export const profileService = {
  profile,
  editProfile,
  currentUserProfile: userProfile.asObservable(),
  get currentProfile() {return userProfile.value }
}
