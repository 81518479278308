import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {authenticationService} from '../../_services/authenticationService';


const lightColor = 'rgba(255, 255, 255, 0.7)';
const styles = theme => ({
    secondary:{
        zindex:0,
    },
    menuButton:{
        marginLeft: -theme.spacing(1)
    },
    iconButtonAvatar:{
        padding:4,
    },
    link:{
        textDecorator:'none',
        color: lightColor,
        '&:hover':{
            color:theme.palette.common.white,
        },
    },
    button:{
        borderColor: lightColor,
    },
});

class Header extends Component{
  constructor(props){
    super(props);
    this.changeTab = this.changeTab.bind(this);
    this.signOut = this.signOut.bind(this);
    this.state={
      profile:JSON.parse(localStorage.getItem('profile'))
    }
  }

  changeTab(val){

    if(this.props.page==='dashboard'){
      if(val===0){
        this.props.onClick("home", 0);
      }
      else if(val===1){
        this.props.onClick("transactions", 1);
      }
      else if(val===2){
        if(this.props.profile.security){
          this.props.onClick("profile", 1);
        }else{
          this.props.onClick("profile", 2);
        }

      }
      else{
        this.props.onClick("organisation", 3);
      }

    }


    else{
      if(this.props.page==='issue'){
        if(val===0){
          this.props.onClick("add-product", 0);
        }
        else if(val===1){
          this.props.onClick("issue-product", 1);
        }
        else{
          this.props.onClick("status", 2);
        }
      }

      if(this.props.page==='approve'){
        if(val===0){
          this.props.onClick("approve", 0);
        }
        else if(val===1){
          this.props.onClick("add-product", 1);
        }
        else{
          this.props.onClick("status", 2);
        }

      }

      if(this.props.page==='user-accounts'){
        if(val===0){
          this.props.onClick("add-user", 0);
        }
        else if(val===1){
          this.props.onClick("users", 1);
        }
        else{
          this.props.onClick("status", 2);
        }

      }
      if(this.props.page==='organisations'){
        if(val===0){
          this.props.onClick("add-org", 0);
        }
        else if(val===1){
          this.props.onClick("organisations", 1);
        }
        else{
          this.props.onClick("status", 2);
        }

      }

      if(this.props.page==='checkout'){
        if(val===0){
          this.props.onClick("org_prod", 0);
        }
        else if(val===1){
          this.props.onClick("personal_prod", 1);
        }
        else{
          this.props.onClick("status", 2);
        }

      }

      if(this.props.page==='checkin'){

        if(val===0){
          this.props.onClick("returned", 0);
        }
        else if(val===1){
          this.props.onClick("personal", 1);
        }
        else{
          this.props.onClick("status", 2);
        }

      }

      if(this.props.page==='security-account'){
        
        if(val===0){
          this.props.onClick("add-security", 0);
        }
        else if(val===1){
          this.props.onClick("manage-security", 1);
        }
        else{
          this.props.onClick("status", 2);
        }

      }
    }
  }

  signOut(){
    authenticationService.logout();
  }
  componentDidMount(){
  }

    render(){
      const {page}=this.props;
      const profile = this.props.profile;
        return(
            <Fragment>
                <AppBar color='primary' position='sticky' elevation={0}>
                    <Toolbar>
                        <Grid container spacing={1}  alignItems="center">
                            <Hidden smUp>
                                <Grid item>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={()=>this.props.onDrawerToggle()}
                                        className={this.props.classes.menuButton}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Hidden>
                            <Grid item xs />
                            <Grid item>
                                <Link className='this.props.classes.link' href='#' variant='body2' color='inherit'>
                                    {profile.email}
                                </Link>
                            </Grid>
                            <Grid item>
                                <IconButton color="inherit" className={this.props.classes.iconButtonAvatar}>
                                    <AccountCircleIcon fontSize='large'/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <AppBar
                component='div'
                className={this.props.classes.secondaryBar}
                color='primary'
                position='static'
                elevation={0}
                >
                    <Toolbar>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs>
                              <Typography variant='h5' color='inherit' component='h1'>
                                  GatePass
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button className={this.props.classes.button} variant='outlined' color='inherit' size='small' onClick={()=>this.signOut()}>
                                Signout
                              </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <AppBar
                  component='div'
                  className={this.props.classes.secondaryBar}
                  color='primary'
                  position='static'
                  elevation={0}
                >
                {page==='dashboard'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Home' name='0' onClick={()=>this.changeTab(0)}/>
                  {!profile.security ?
                  <Tab textColor='inherit' label='Transactions' onClick={()=>this.changeTab(1)}/>
                  :
                  null
                  }
                  <Tab
                    textColor='inherit'
                    label='Profile'
                    onClick={()=>this.changeTab(2)}
                  />
                  </Tabs>
                  :
                  null
                }
                {page==='issue'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Add product' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='issue product' onClick={()=>this.changeTab(1)}/>
                  <Tab textColor='inherit' label='Status' onClick={()=>this.changeTab(2)}/>
                  </Tabs>
                  :
                  null
                }
                {page==='approve'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Approve' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='Add product' onClick={()=>this.changeTab(1)}/>
                  <Tab textColor='inherit' label='Status' onClick={()=>this.changeTab(2)}/>
                  </Tabs>
                  :
                  null
                }

                {page==='user-accounts'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='New user' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='Users' onClick={()=>this.changeTab(1)}/>
                  {profile.system_admin?
                  <Tab textColor='inherit' label='Manage Security' onClick={()=>this.changeTab(2)}/>
                  :
                  null
                  }
                  </Tabs>
                  :
                  null
                }

                {page==='organisations'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Add org' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='Organisations' onClick={()=>this.changeTab(1)}/>
                  <Tab textColor='inherit' label='Status' onClick={()=>this.changeTab(2)}/>
                  </Tabs>
                  :
                  null
                }

                {page==='checkout'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Organisation Products' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='Personal Products' onClick={()=>this.changeTab(1)}/>
                  </Tabs>
                  :
                  null
                }

                {page==='checkin'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Returned' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='Personal' onClick={()=>this.changeTab(1)}/>
                  </Tabs>
                  :
                  null
                }

                {page==='security-account'?
                  <Tabs value={this.props.tabVal} textColor='inherit'>
                  <Tab textColor='inherit' label='Add Security User' name='0' onClick={()=>this.changeTab(0)}/>
                  <Tab textColor='inherit' label='Manage Security Users' onClick={()=>this.changeTab(1)}/>
                  </Tabs>
                  :
                  null
                }
                </AppBar>

            </Fragment>
        )
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
}
export default withStyles(styles)(Header)
