import React, {Fragment, Component} from 'react';
import ProductList from '../issue/ProductList';
import ApproveProduct from './ApproveProduct';
import Status from '../issue/Status';

class Approve extends Component{

  render(){
    const {subpage} = this.props
    console.log(subpage)
    return(
      <Fragment>
        {subpage === 'add-product' ?
          <ProductList />
        :
        null
        }

        {subpage === 'approve' ?
          <ApproveProduct />
        :
        null
        }
        {subpage === 'status' ?
          <Status />
        :
        null
        }
      </Fragment>
    );
  }
}

export default Approve;
