import React, {Fragment, Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel  from '@material-ui/core/InputLabel';
import Input  from '@material-ui/core/Input';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import {withFormik} from 'formik';
import * as Yup from 'yup';

import {productService} from '../../../_services/productService';


class AddProductForm extends Component{
  constructor(props){
    super(props)
    this.setError = this.setError.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state={
      success:false,
      open: false,
      error: false,
      loading: true,
      prototypes:'',
      count:0,
      data:'',
    }
  }
  setError(val){
    if(this.state.error === false){
      this.setState({
        error: val,
        open: true,
        success: false,
        count: 1,
      });
    }
  }

  setSuccess(val){
    if(this.state.success === false){
      this.setState((state)=>({
        success: val,
        error: false,
        open:true,
        data: this.props.status.data,
      }))
    }
  }
  handleClose(){
    this.setState((state)=>({
      open:false,
      success: false,
      error: false,
      count: 0
    }))
    this.props.setStatus({error: undefined, success:undefined})
  }
  componentDidUpdate(){
    if(this.props.status!==undefined){
      if(this.props.status.success !== undefined){
        this.setSuccess(this.props.status.success)
      }
      if(this.props.status.error !== undefined){
        if(this.state.count === 0){
          this.setError(this.props.status.error)
        }
      }
    }
  }

  componentDidMount(){
    const interval = setInterval( ()=>{
      if(this.props.loading === false){
        const prototypes = this.props.prototypes;
        const values = []
        prototypes.map( prototype =>{
          const entry = {label:`Type: ${prototype.product_type}  Brand: ${prototype.product_brand}  Model: ${prototype.model}`, value:prototype.id}
          values.push(entry)
          return ''
        });
        this.setState( state=> ({
          prototypes: values
        }))

        if(this.state.prototypes !==''){
          this.setState({loading: false })
          clearInterval(interval);
        }

      }

    },100)
  }

  render(){
    return(
      <Fragment>
      {this.state.success === true ?
        <Snackbar
          message={
            <div id='message-id'>
                Product Created
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }

      {this.state.error === true ?
        <Snackbar
          message={
            <div id='message-id'>
              {this.props.status.message}
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }

      <form onSubmit={this.props.handleSubmit}>
        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.product && this.props.errors.product)? true : false }
        >
          <Select
            id='product'
            name='product'
            options={this.state.loading? '': this.state.prototypes}
            isClearable={true}
            isSearchable={true}
            isLoading={this.state.loading}
            value={this.props.values.product}
            onChange={event=>this.props.setFieldValue('product', event)}
            onBlur={this.props.handleBlur}
          />
          <FormHelperText id="my-helper-text">Select Product</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth={true}
          className={this.props.classes.formControl}
          error={(this.props.touched.serial_number && this.props.errors.serial_number)? true : false }
        >
          <InputLabel htmlFor="serial_number">Serial Number</InputLabel>
          <Input
            id="serial_number"
            name="serial_number"
            value={this.props.values.serial_number || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            aria-describedby="my-helper-text"
            required={true}
          />
          <FormHelperText id="my-helper-text">Serial Number</FormHelperText>
      </FormControl>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              id='returnable'
              name='returnable'
              color="primary"
              value={this.props.values.returnable}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              checked={this.props.values.returnable || false}
              />
            }
            label="Product is Returnable"
        />
      </FormGroup>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={this.props.classes.submit}
      >
        <AddIcon />
        Add Product
      </Button>

      </form>

      </Fragment>
    );
  }
}

export default withFormik({
  mapPropsToValues: ()=>({
    product:{label:'', value:''},
    serial_number: '',
    returnable: false
  }),
  validationSchema: Yup.object().shape({
    product: Yup.object().shape({
      label: Yup.string().required('Required!!'),
      value: Yup.string().required('Required!!'),
    }),
    serial_number: Yup.string().required('Required!!')
  }),
  handleSubmit:(values, {setSubmitting, setStatus, setErrors, resetForm}) =>{
    productService.addProduct(values)
      .then( data => {
        if(data !== ''){
          setStatus({success: true, data:data });
          setSubmitting(true);
          resetForm();
        }else{
          setSubmitting(false);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if(err.message === 'Request failed with status code 400'){
          setStatus({error: true, message:'Product with similar serial number exists'})
          setErrors({serial_number: 'product already exists'})
        }else{
          setStatus({error: true, message:err.message})
        }
      })
  }
})(AddProductForm);
