import React, {Component, Fragment} from 'react';

import List from './list';
import AddOrganisation from './addOrganisation';

class Organisations extends Component{

  render(){
    return(
      <Fragment>
        {this.props.subpage==='organisations'?
          <List />
          :
          null
        }

        {this.props.subpage==='add-org'?
          <AddOrganisation />
          :
          null
        }
      </Fragment>
    );
  }
}

export default Organisations
