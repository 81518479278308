import React, {Fragment, Component} from 'react';
import SecurityIcon from '@material-ui/icons/Security';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import CreateSecurityUser  from './CreateSecurityUser';
import SecurityUsers from './SecurityUsersList';


const styles =theme => ({
    paper:{
      marginTop: 10,
      padding: theme.spacing(3, 2)
    },
    formControl:{
      margin: 10
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    icon:{
      marginRight: 5,
    }
  })

class ManageSecurity extends Component{
    constructor(props){
        super(props);
        this.setValue = this.setValue.bind(this);
        this.state={
            value: 0,
        }
    }

    setValue(val){
        this.setState({value:val})
    }

    render(){
        const value = this.state.value;
        return(
        <Fragment>
            <BottomNavigation
                value={this.state.value}
                showLabels
                style={{backgroundColor:'inherit'}}
            >   
                <Tooltip title="Create user with role SECURITY" arrow>
                    <BottomNavigationAction label="Create User(Security)"  icon={<SecurityIcon />} onClick={()=>this.setValue(0)}/>
                </Tooltip>
                <Tooltip title="view and manage users with role SECURITY" arrow>
                    <BottomNavigationAction label="View security"  icon={<VerifiedUserIcon />} onClick={()=>this.setValue(1)}/>
                </Tooltip>                
            </BottomNavigation>

            {value === 0 ?
            <Paper className={this.props.classes.paper}>
                <CreateSecurityUser classes={this.props.classes} profile={this.props.profile}/>
            </Paper>
            :
            <Paper className={this.props.classes.paper}>
                <SecurityUsers classes={this.props.classes} profile={this.props.profile}/>
            </Paper>
            }
        </Fragment>
        );
    }
}

export default withStyles(styles)(ManageSecurity);