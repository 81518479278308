import axios from  'axios';
import {authenticationService} from './authenticationService';


const getUsers = async ()=>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/users`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const getAdmins = async ()=>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/organization-admin`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const addUser = async(values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/users`, {
    username: values.username,
    password: values.password,
    email: values.email,
    user_id: values.user_id,
    first_name: values.first_name,
    last_name: values.last_name,
    organisation_admin: values.organisation_admin,
    can_issue: values.can_issue,
    can_approve: values.can_approve
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp => {
    data=resp.data;
    return data;
  })
  return data;
}

const addOrgAdmin = async(values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/organization-admin`, {
    username: values.username,
    password: values.password,
    email: values.email,
    user_id: values.user_id,
    first_name: values.first_name,
    last_name: values.last_name,
    organisation: values.organisation.value
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp => {
    data=resp.data;
    return data;
  })
  return data;
}

const addSecurity = async(values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/users/security`, {
    username: values.username,
    password: values.password,
    email: values.email,
    user_id: values.user_id,
    first_name: values.first_name,
    last_name: values.last_name,
    organisation: values.organisation.value,
    organisation_admin: values.admin,
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp => {
    data=resp.data;
    return data;
  })
  return data;
}

export const userService={
  getUsers,
  getAdmins,
  addUser,
  addOrgAdmin,
  addSecurity
}
