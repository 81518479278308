import axios from  'axios';
import {authenticationService} from './authenticationService';

const checkoutList = async () =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.get(`${api_url}/api/v1/checkout`,{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const checkout = async (id) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.put(`${api_url}/api/v1/checkout/${id}`,{check_out: true},{
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp =>{
    data = resp.data;
    return data
  })
  return data
}

const addSecurity = async(values) =>{
  const api_url = 'http://197.232.56.28:1010';
  let data = ''
  await axios.post(`${api_url}/api/v1/security`, {
    username: values.username,
    password: values.password,
    email: values.email,
    user_id: values.user_id,
    first_name: values.first_name,
    last_name: values.last_name,
    organisation_admin: values.organisation_admin,
  },
  {
    headers:{
      'Authorization': `Bearer ${authenticationService.currentUserValue}`,
      'content-type':'application/json'
    }
  })
  .then(resp => {
    data=resp.data;
    return data;
  })
  return data;
}


export const securityService={
  checkoutList,
  checkout,
  addSecurity

}
