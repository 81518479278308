import React, {Fragment, Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IssueProductForm from './IssueProductForm';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2)
  },
  formControl:{
    margin: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon:{
    marginRight: 5,
  }
})
class IssueProduct extends Component{

  render(){
    return(
      <Fragment>
      <Paper className={this.props.classes.paper}>
        <IssueProductForm  classes={this.props.classes}/>
      </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(IssueProduct);
