import React, {Component, Fragment} from 'react';

import AddUser from './AddUser';
import ListUsers from './ListUsers';
import ManageSecurity from './ManageSecurity';


class Users extends Component{

  render(){
    const {subpage, profile} = this.props;
    return(
      <Fragment>
        {subpage === 'add-user'?
          <AddUser profile={profile}/>
        :
          null
        }
        {subpage === 'users'?
          <ListUsers profile={profile}/>
        :
          null
        }

        {subpage === 'status'?
          <ManageSecurity profile={profile} />
        :
          null
        }
        
      </Fragment>
    );
  }
}

export default Users;
