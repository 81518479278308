import React, {Fragment, Component} from 'react';


class SecurityUsers extends Component{

    render(){
        return(
            <Fragment>
                <p>we are here</p>
            </Fragment>
        );
    }

}

export default SecurityUsers