import React, {Component, Fragment} from 'react';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel  from '@material-ui/core/InputLabel';
import Input  from '@material-ui/core/Input';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import {profileService} from '../../../_services/profileService';
import Snackbar from '@material-ui/core/Snackbar';


class ProfileUpdateForm extends Component{
  constructor(props){
    super(props);
    this.setError = this.setError.bind(this);
    this.setSuccess= this.setSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state={
      success: false,
      error: false,
      open: false,
      count: 0
    }
  }

  setSuccess(val){
    if(this.state.success === false){
      this.setState((state)=>({
        success: val,
        error: false,
        open:true
      }))
    }
  }

  handleClose(){
    this.setState((state)=>({
      open:false,
      success: false,
      error: false,
      count: 0
    }))
    this.props.setStatus({error: undefined, success:undefined})
  }

  setError(val){
    if(this.state.error === false){
      this.setState({
        error: val,
        open: true,
        success: false,
        count: 1,
      });
    }
  }

  componentDdMount(){

  }

  componentDidUpdate(){
    if(this.props.status!==undefined){
      if(this.props.status.success !== undefined){
        this.setSuccess(this.props.status.success)
      }
      if(this.props.status.error !== undefined){
        if(this.state.count === 0){
          this.setError(this.props.status.error)
        }

      }
    }
  }

  render(){

    return(
      <Fragment>

{this.state.success === true ?
        <Snackbar
          message={
            <div id='message-id'>
              Profile Updated
            </div>
            }
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
          'aria-describedby': 'message-id',
          }}
        />
        :
        null
      }

{this.state.error === true ?
        <Snackbar
          message={
            <div id='message-id'>
              {this.props.status.message}
            </div>
          }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        />
        :
        null
      }

        <form onSubmit={this.props.handleSubmit}>
          <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            error = {(this.props.touched.username && this.props.errors.username)? true : false}
            >
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input
                id="username"
                name="username"
                aria-describedby="my-helper-text"
                required={true}
                value={this.props.values.username || ''}
                onChange={this.props.handleChange}
                onBlur={this.props.handleBlur}
              />
              <FormHelperText id="my-helper-text">Update Username</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth={true}
              className={this.props.classes.formControl}
              error = {(this.props.touched.email && this.props.errors.email)? true : false}
              >
                <InputLabel htmlFor="username">email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  aria-describedby="my-helper-text"
                  required={true}
                  value={this.props.values.email || ''}
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                />
                <FormHelperText id="my-helper-text">Update Email</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth={true}
                className={this.props.classes.formControl}
                error = {(this.props.touched.user_id && this.props.errors.user_id)? true : false}
                >
                  <InputLabel htmlFor="user_id">User Id</InputLabel>
                  <Input
                    id="user_id"
                    name="user_id"
                    aria-describedby="my-helper-text"
                    required={true}
                    value={this.props.values.user_id || ''}
                    onChange={this.props.handleChange}
                    onBlur={this.props.handleBlur}
                  />
                  <FormHelperText id="my-helper-text">Update Id</FormHelperText>
              </FormControl>

              <FormControl
                fullWidth={false}
                className={this.props.classes.formControl}
                error = {(this.props.touched.first_name && this.props.errors.first_name)? true : false}
                >
                  <InputLabel htmlFor="first_name">First Name</InputLabel>
                  <Input
                    id="first_name"
                    name="first_name"
                    aria-describedby="my-helper-text"
                    required={true}
                    value={this.props.values.first_name || ''}
                    onChange={this.props.handleChange}
                    onBlur={this.props.handleBlur}
                  />
                  <FormHelperText id="my-helper-text">Update First Name</FormHelperText>
                </FormControl>

                <FormControl
                  fullWidth={false}
                  className={this.props.classes.formControl}
                  error = {(this.props.touched.last_name && this.props.errors.last_name)? true : false}
                  >
                    <InputLabel htmlFor="last_name">Last Name</InputLabel>
                    <Input
                      id="last_name"
                      name="last_name"
                      aria-describedby="my-helper-text"
                      required={true}
                      value={this.props.values.last_name || ''}
                      onChange={this.props.handleChange}
                      onBlur={this.props.handleBlur}
                    />
                    <FormHelperText id="my-helper-text">Update Last Name</FormHelperText>
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={this.props.classes.submit}
                  >
                    <CreateIcon />
                    Update Details
                  </Button>
        </form>
      </Fragment>

    );
  }
}

export default withFormik({
  mapPropsToValues:(props)=>({
    username: props.profile.username,
    email: props.profile.email,
    user_id: props.profile.user_id,
    first_name: props.profile.first_name,
    last_name: props.profile.last_name
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().min(3, 'Should be atleast 3 characters').required('Required!!'),
    user_id:Yup.string().min(3, 'Should be atleast 3 characters').required('Required!!'),
    email: Yup.string().required('Required!!'),
    first_name: Yup.string().required('Required!!'),
    last_name: Yup.string().required('Required!!')

  }),
  handleSubmit: (values, {setSubmitting, setStatus, setErrors, resetForm, props} )=>{
    profileService.editProfile(values)
    .then(data=>{
      if(data!==''){
        setStatus({success:true})
        setSubmitting(true);
      }
    })
    .catch(error=>{
      setSubmitting(false);
      if(error.message === 'Request failed with status code 400'){
        setStatus({error:true, message:"Invalid Email/UserId"});
      }
      else{
        setStatus({error: true, message:error.message})
      }


    })
  }
})(ProfileUpdateForm);
