import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Spinner from '../../common/Spinner';
import Paper from '@material-ui/core/Paper';

import {productService} from '../../../_services/productService';
import AddProduct from './AddProduct';
import AddPrototype from './AddPrototype';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
    overflowX: 'auto',
  },
  tableWrapper:{
    marginTop: 10,
    maxHeight: 440,
    overflow: 'auto'
  },
})


class ProductList extends Component{
  constructor(props){
    super(props)
    this.setValue = this.setValue.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.setPage = this.setPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.state={
      products:'',
      prototypes:'',
      loading: true,
      value:0,
      page:0,
      rowsPerPage: 5
    }
  }
  setPage(val){
    this.setState({page: val})
  }
  setRowsPerPage(val){
    this.setState({rowsPerPage: val})
  }
  handleChangeRowsPerPage(event){
    this.setPage(0);
    this.setRowsPerPage(+event.target.value)
  }
  handleChangePage(event, newPage){
    this.setPage(newPage);
  }

  setValue(val){
    this.setState({value: val});
    this.getProducts();
  }

  getProducts(){
    productService.getProducts()
      .then(val=>{
        this.setState(state=>({
          products: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      productService.getPrototypes()
        .then(val=>{
          this.setState(state=>({
            prototypes: val
          }))
        })
        .catch(error =>{
          alert(error)
        })
      const interval = setInterval( ()=>{
        if(this.state.products !== '' && this.state.prototypes !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }

  componentDidMount(){
    this.getProducts();
  }


  render(){
    const products = this.state.products;
    return(
      <Fragment>
        <BottomNavigation
          value={this.state.value}
          showLabels
          style={{backgroundColor:'inherit'}}
        >
          <BottomNavigationAction label="Available Products"  icon={<ViewListIcon />} onClick={()=>this.setValue(0)}/>
          <BottomNavigationAction label="Add Product"  icon={<AddToQueueIcon />} onClick={()=>this.setValue(1)}/>
          <BottomNavigationAction label="Create Product Prototype"  icon={<PostAddIcon />} onClick={()=>this.setValue(2)}/>
        </BottomNavigation>

        {this.state.value === 0?
          <Fragment>
            {this.state.loading?
              <Spinner/>
              :
              <Paper className={this.props.classes.paper}>
              <div className={this.props.classes.tableWrapper}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <TableCell >Product Type</TableCell>
                      <TableCell >Serial Number</TableCell>
                      <TableCell >Product Brand</TableCell>
                      <TableCell >Product Model</TableCell>
                      <TableCell >Date Added</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map( product => {
                      const prototype = this.state.prototypes.find(prototype => prototype.id === product.type)
                      return(
                        <TableRow key={product.id}>
                          <TableCell >{prototype.product_type}</TableCell>
                          <TableCell >{product.serial_number}</TableCell>
                          <TableCell >{prototype.product_brand}</TableCell>
                          <TableCell >{prototype.model}</TableCell>
                          <TableCell >{new Date(product.timestamp).toLocaleString()}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={this.state.products.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{'aria-label': 'previous page',}}
                nextIconButtonProps={{'aria-label': 'next page',}}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
              </Paper>
            }
          </Fragment>
          :
          null
        }
        {this.state.value ===1 ?
          <AddProduct changeTab={this.setValue} prototypes={this.state.prototypes} loading={this.state.loading}/>
          :
          null
        }
        {this.state.value ===2 ?
          <AddPrototype changeTab={this.setValue} prototypes={this.state.prototypes} loading={this.state.loading}/>
          :
          null
        }
      </Fragment>
    );
  }
}

export default withStyles(styles)(ProductList);
