import React, {Component, Fragment} from 'react';
import Spinner from '../../../common/Spinner';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import {securityService} from '../../../../_services/securityService';


const styles = theme =>({
  tableWrapper:{
    marginTop: 10,
    maxHeight: 440,
    overflow: 'auto'
  },
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
    overflowX: 'auto',
  },
  progress: {
    margin: theme.spacing(10),
  },

})


class OrgProducts extends Component{
  constructor(props){
    super(props)
    this.getData = this.getData.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.state={
      loading: true,
      page:0,
      rowsPerPage:5,
      data:'',
      currentData:'',
      organisations:'',
      dialog: false
    }
  }

  getData(){
    securityService.checkoutList()
      .then(val=>{
        this.setState(state=>({
          data: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.users !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }
  handleCheckout(val){
    const data = this.state.data.filter(record => record.id===val)
    this.setState({
      dialog: true,
      currentData: data
    })
  }
  handleDialogClose(val){
    if(val===0){
      this.setState({dialog: false, currentData:''})
    }
    else{
      securityService.checkout(this.state.currentData[0].id)
        .then( val=>{
          if(val !== ''){
            this.setState({dialog: false, currentData:''})
            this.getData()
          }
        })
        .catch(err =>{
          this.setState({dialog: false, currentData:''})
          alert(err.message)
        })
    }
  }

  componentDidMount(){
    this.getData()
  }

  render(){
    const columns = [
      {
        name: "Company",
        options:{
          filter:true,
        }
      },
      {
        name: "Name",
        options:{
          filter:true,
        },
      },
      {
        name: "Id Number",
        options:{
          filter:true,
        }
      },
      {
        name: "Devices",
        options:{
          filter:true,
        },
      },
      {
        name: "...",
        options:{
          filter:true,
          empty: false,
          customBodyRender: (value, tableMeta, updateValue)=>{
            return(
              <Button color="primary" onClick={()=>this.handleCheckout(tableMeta.rowData[4])}>
                <AssignmentTurnedInIcon/>
              </Button>
            )
          }
        },
      }
    ];
    let data = [];
    if(this.state.data !== ''){
      this.state.data.map(val => {
        const entry = [val.organisation, `${val.first_name} ${val.last_name}`, val.person_id, val.product.length, val.id ]
        data.push(entry)
        return ''
      })
    }
    const options = {
      filterType: 'textField',
      responsive: 'scrollMaxHeight',
      rowsPerPageOptions: [5, 10, 100],
      rowsPerPage:5,
      pagination: true,
      selectableRowsHeader: false,
      selectableRows: "none"
    };

    return(
      <Fragment>
       {this.state.loading ?
         <Spinner />
         :
         <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
         <MUIDataTable
            title={"Checkout"}
            data={data}
            columns={columns}
            options={options}

        />
         </div>

        }
        <Dialog
          maxWidth='sm'
          open={this.state.dialog}
          onClose={this.handleClose}
          aria-labelledby="checkout-confirmation"
        >
          <DialogTitle id="checkout-confirmation">
          {"Checkout Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText component='div' style={{ textAlign:"center" }}>
              {this.state.currentData === ''?
                <CircularProgress/>
              :

                <Fragment>
                  <span>{this.state.currentData[0].issue_comment}</span>
                  <Divider/>
                  {this.state.currentData[0].product.map(prod => {
                    return(
                      <p key={prod.serial_number}>{prod.type}</p>
                    );
                  }) }
                </Fragment>
              }

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={()=>this.handleDialogClose(0)} color="primary">
              Cancel
            </Button>
            <Button onClick={()=>this.handleDialogClose(1)} color="primary" autoFocus>
              Checkout
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrgProducts);
