import React, {Component, Fragment} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PeopleIcon from '@material-ui/icons/People';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Spinner from '../../common/Spinner';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {userService} from '../../../_services/userService';

const styles =theme => ({
  paper:{
    marginTop: 10,
    padding: theme.spacing(3, 2),
    overflowX: 'auto',
  },
  tableWrapper:{
    marginTop: 10,
    maxHeight: 440,
    overflow: 'auto'
  },
})


class ListUsers extends Component{
  constructor(props){
    super(props)
    this.setValue = this.setValue.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.getAdmins = this.getAdmins.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.state={
      users:'',
      loading: true,
      systemAdmin: this.props.profile.system_admin,
      value:0,
      page:0,
      rowsPerPage:5
    }
  }

  setPage(val){
    this.setState({page: val})
  }
  setRowsPerPage(val){
    this.setState({rowsPerPage: val})
  }

  handleChangeRowsPerPage(event){
    this.setPage(0);
    this.setRowsPerPage(event.target.value)
  }

  handleChangePage(event, newPage){
    this.setPage(newPage);
  }

  setValue(newVal){
    this.setState({value: newVal, users:'', loading: true});
    if(newVal===0){
      this.getUsers()
    }else{
      this.getAdmins()
    }
  }

  getUsers(){
    userService.getUsers()
      .then(val=>{
        this.setState(state=>({
          users: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.users !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }
  getAdmins(){
    userService.getAdmins()
      .then(val=>{
        this.setState(state=>({
          users: val
        }))
      })
      .catch(error =>{
        alert(error)
      })
      const interval = setInterval( ()=>{
        if(this.state.users !== ''){
          this.setState({loading: false})
          clearInterval(interval);
        }
      },100)
  }
  componentDidMount(){
    this.getUsers()
  }

  render(){
    const users = this.state.users;
    const page = this.state.page;
    const rowsPerPage = this.state.rowsPerPage;
    return(
      <Fragment >
      {this.state.loading?
        <Spinner />
      :

      <Fragment>
      {this.state.systemAdmin ?
        <BottomNavigation
          value={this.state.value}
          showLabels
          style={{backgroundColor:'inherit'}}
          >
          <BottomNavigationAction label="All users"  icon={<PeopleIcon />} onClick={()=>this.setValue(0)}/>
          <BottomNavigationAction label="Org/Dep Admins"  icon={<VerifiedUserIcon />} onClick={()=>this.setValue(1)}/>
        </BottomNavigation>
        :
        null
      }
      <Paper className={this.props.classes.paper}>
      {this.state.value === 0 ?
        <Typography>View All Users</Typography>
        :
        <Typography>View Organisation Admins</Typography>
      }
      <div className={this.props.classes.tableWrapper}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>....</TableCell>
              <TableCell align='right'>First Name</TableCell>
              <TableCell align='right'>Last Name</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>Organisation</TableCell>
              {this.state.value ===0 ?
                <TableCell align='right'>Can Issue</TableCell>
                :
                null
              }
              {this.state.value ===0 ?
                <TableCell align='right'>Can Approve</TableCell>
                :
                null
              }
              <TableCell align='right'>Admin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( user =>{
              return(
                <TableRow hover role='checkbox' tabIndex={-1} key={user.user_id}>
                  <TableCell align='right'><AssignmentIndIcon color="primary"/></TableCell>
                  <TableCell align='right'>{user.first_name}</TableCell>
                  <TableCell align='right'>{user.last_name}</TableCell>
                  <TableCell align='right'>{user.email}</TableCell>
                  <TableCell align='right'>{user.organisation}</TableCell>
                  {this.state.value ===0 ?
                    <TableCell align='right'>{user.can_issue?
                      <CheckCircleIcon color='primary'/>
                      :
                      null
                    }
                    </TableCell>
                    :
                    null
                  }
                  {this.state.value ===0 ?
                    <TableCell align='right'>{user.can_approve?
                      <CheckCircleIcon color='primary'/>
                    :
                    null
                  }
                    </TableCell>
                    :
                    null
                  }
                  <TableCell align='right'>{user.organisation_admin ?
                    <CheckCircleIcon color='primary'/>
                    :
                    null
                  }</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={this.state.users.length}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        backIconButtonProps={{'aria-label': 'previous page',}}
        nextIconButtonProps={{'aria-label': 'next page',}}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
      </Paper>
      </Fragment>
    }
    </Fragment>
    );
  }
}

export default withStyles(styles)(ListUsers);
